import axios from "axios"

import { handleError } from "../utils/error"
import { authActions } from "../store/auth-store"
import { appointmentsActions } from "../store/appointments-store"
import { Moment } from "moment"
import { NewAppointment } from "../types/appointments"

export const deleteAppointmentById = (id: string) => async (dispatch: any) => {
  try {
    await axios.delete(`appointment/${id}`)
    dispatch(appointmentsActions.deleteAppointment(id))
  } catch (error) {
    handleError(error)
  }
}

export const getAvailableTimeSlots = (durationInMinutes: number, date: Moment) => async (dispatch: any) => {
  try {
    const { data } = await axios.get(`appointment/available-slots/?durationInMinutes=${durationInMinutes}&date=${date.format("YYYY-MM-DD")}`)
    dispatch(appointmentsActions.setAvailableSlots(data))
  } catch (error) {
    handleError(error)
  }
}

export const createNewAppointment = ({dateTime, durationInMinutes, patientId}: NewAppointment) => async (dispatch: any) => {
  try {
    const { data } = await axios.post("appointment/create", {
      dateTime: dateTime?.toString(),
      durationInMinutes,
      patientId,
    })
    dispatch(appointmentsActions.addAppointment(data))
  } catch (error) {
    handleError(error)
  }
}
