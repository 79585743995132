import { useState, ChangeEvent } from "react"
import { useDispatch } from "react-redux"

import { multipleAppointmentsPatient } from "../../actions/patients"
import LoadingCheckbox from "../loading-checkbox/LoadingCheckbox"

interface Props {
  patientId: string
  checked: boolean
}

const PatientMultipleAppointmentsCheckbox = ({ patientId, checked }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch<any>()

  const multipleAppointmentsHandler = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)
    await dispatch(multipleAppointmentsPatient(patientId, target.checked))
    setIsLoading(false)
  }

  return (
    <LoadingCheckbox
      onChange={multipleAppointmentsHandler}
      checked={checked}
      isLoading={isLoading}
    />
  )
}

export default PatientMultipleAppointmentsCheckbox
