import { Dispatch, SetStateAction, ChangeEventHandler, useState } from "react"

import "./SearchInput.scss"

interface Props {
  setValue: Dispatch<SetStateAction<string>>
  value: string
}

const SearchInput = ({ value, setValue, ...restProps }: Props) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }

  const closeSearchHandler = () => {
    setValue("")
    setIsSearchOpen(false)
  }

  return (
    <div className={`search-input ${isSearchOpen ? "active" : ""}`}>
      <div className='search-wrapper'>
        <div className='input-holder'>
          <input
            type='text'
            placeholder='חיפוש...'
            {...restProps}
            value={value}
            onChange={changeHandler}
          />
          <button className='search-icon' onClick={() => setIsSearchOpen(true)}>
            <span></span>
          </button>
        </div>
        <span className='close' onClick={closeSearchHandler}></span>
      </div>
    </div>
  )
}

export default SearchInput
