import { useState } from "react"
import Loading from "../../components/small-loading/SmallLoading"
import SmallLoading from "../../components/small-loading/SmallLoading"
import "./Sms.scss"
import ConfirmModal from "../../components/confirm-modal/ConfirmModal"
import axios from "axios"
import { toast } from "react-toastify"

const Sms = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const submitHandler = (e: any) => {
    e.preventDefault()
    // confirm dialog
    // start loading
    // send sms
    // stop loading
    // show success message
    // show error message
  }

  return (
    <div className='sms'>
      <form onSubmit={submitHandler}>
        <h1>SMS לכולם</h1>
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <div className='buttons'>
          {false ? (
            <Loading height={38} />
          ) : (
            <>
              {isLoading ? (
                <SmallLoading height={43} />
              ) : (
                <button
                  onClick={() => setIsModalOpen(true)}
                  type='submit'
                  className='main-button publish'
                >
                  שליחה
                </button>
              )}
            </>
          )}
        </div>
      </form>
      {isModalOpen && (
        <ConfirmModal
          title='אישור שליחת הודעה'
          description='האם אתה בטוח שברצונך לשלוח הודעה לכל המשתמשים?'
          onConfirm={async () => {
            try {
              setIsLoading(true)
              await axios.post("setting/sms", { message })
              toast.success("ההודעה נשלחה בהצלחה")
            } catch (err) {
              toast.error("ההודעה לא נשלחה יש שגיאה")
            } finally {
              setMessage("")
              setIsLoading(false)
            }
          }}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default Sms
