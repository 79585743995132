import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled"
import EmailIcon from "@mui/icons-material/Email"
import GroupsIcon from "@mui/icons-material/Groups"
import PauseIcon from "@mui/icons-material/Pause"
import SettingsIcon from "@mui/icons-material/Settings"
import SmsIcon from '@mui/icons-material/Sms';

import CalendarAppointments from "../pages/calendar-appointments/CalendarAppointments"
import ListAppointments from "../pages/list-appointments/ListAppointments"
import Popup from "../pages/popup/Popup"
import Patients from "../pages/patients/Patients"
import Waitings from "../pages/waitings/Waitings"
import Sms from "../pages/sms/Sms"
import Settings from "../pages/settings/Settings"

export const getAllRoutes = () => [
    {
        iconComponent: CalendarMonthIcon,
        pageComponent: CalendarAppointments,
        label: "תורים בלוח שנה",
        path: "/",
    },
    {
        iconComponent: AccessTimeFilledIcon,
        pageComponent: ListAppointments,
        label: "תורים ברשימה",
        path: "/list-appointments",
    },
    {
        iconComponent: EmailIcon,
        pageComponent: Popup,
        label: "מודעה קופצת",
        path: "/popup",
    },
    {
        iconComponent: GroupsIcon,
        pageComponent: Patients,
        label: "לקוחות",
        path: "/patients",
    },
    {
        iconComponent: PauseIcon,
        pageComponent: Waitings,
        label: "ממתינים",
        path: "/waitings",
    },
    {
        iconComponent: SmsIcon,
        pageComponent: Sms,
        label: "שליחת אסאמאס",
        path: "/sms",
    },
    {
        iconComponent: SettingsIcon,
        pageComponent: Settings,
        label: "הגדרות",
        path: "/settings",
    },
]
