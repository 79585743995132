import { useState } from "react"
import Rodal from "rodal"
import MultiStep from "react-multistep"

import PickDate from "./PickDate"
import PickTime from "./PickTime"
import PickPatient from "./PickPatient"
import { NewAppointment } from "../../types/appointments"

import "./NewAppointmentModal.scss"
import { useDispatch } from "react-redux"
import moment from "moment"
import { toast } from "react-toastify"
import { createNewAppointment } from "../../actions/appointments"

interface Props {
  onClose: () => void
}

const NewAppointmentModal = ({ onClose }: Props) => {
  const [newAppointment, setNewAppointment] = useState<NewAppointment>({
    dateTime: null,
    durationInMinutes: 15,
    patientId: "",
  })
  const dispatch = useDispatch<any>()

  const submitHandler = async () => {
    const { dateTime, durationInMinutes, patientId } = newAppointment
    if (
      !dateTime ||
      dateTime.isBefore(moment(), "day") ||
      dateTime.format("HH:mm") === "00:00" ||
      !/^\d+$/.test(durationInMinutes + "") ||
      !patientId
    ) {
      toast.error("יש למלא את כל השלבים בתקינות")
      return
    }
    await dispatch(createNewAppointment(newAppointment))
    toast.success("התור נקבע בהצלחה")
    onClose()
  }

  return (
    <Rodal className='new-appointment-modal' visible={true} onClose={onClose}>
      <MultiStep showNavigation={false}>
        <PickDate
          dateTime={newAppointment.dateTime}
          onChange={setNewAppointment}
          title='תאריך'
        />
        <PickTime
          durationInMinutes={newAppointment.durationInMinutes}
          dateTime={newAppointment.dateTime}
          onChange={setNewAppointment}
          title='זמן'
        />
        <PickPatient
          patientId={newAppointment.patientId}
          onSubmit={submitHandler}
          onChange={setNewAppointment}
          title='לקוח'
        />
      </MultiStep>
    </Rodal>
  )
}

export default NewAppointmentModal
