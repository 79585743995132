import { useState, useEffect, Dispatch, SetStateAction, ChangeEventHandler } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment, { Moment } from "moment"

import SmallLoading from "../small-loading/SmallLoading"
import { getAvailableTimeSlots } from "../../actions/appointments"
import { AvailableTimeSlot, NewAppointment } from "../../types/appointments"

interface Props {
  title: string
  onChange: Dispatch<SetStateAction<NewAppointment>>
  dateTime: Moment | null
  durationInMinutes: number
}

const PickTime = ({durationInMinutes, dateTime, onChange }: Props) => {
  const { availableSlots } = useSelector((state: any) => state.appointments)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch<any>()

  const chooseTimeHandler = (chosenDate: Moment) => {
    onChange((p) => ({ ...p, dateTime: chosenDate }))
  }

  const changeDurationHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange((p) => ({ ...p, durationInMinutes: parseInt(e.target.value) }))
  }

  const didChooseValidDate = dateTime && !dateTime.isBefore(moment(), "day")
  
  useEffect(() => {
    if (didChooseValidDate) {
      setIsLoading(true)
      dispatch(
        getAvailableTimeSlots(durationInMinutes, dateTime)
      ).finally(() => {
        setIsLoading(false)
      })
    }
    // Don't include dateTime in Deps because of its parent
    // who take care of it  by refreshing this all section when dateTime changes.
  }, [dispatch, setIsLoading, durationInMinutes])

  return (
    <div className='pick-time'>
      {didChooseValidDate && !moment(dateTime).isBefore(moment(), "day")? (
        <>
          <div className='duration'>
            משך הפגישה:
            <input
              className='duration-input'
              onChange={changeDurationHandler}
              placeholder="להשאיר ריק עבור 15 דק'"
            />
          </div>
          {isLoading ?
            <SmallLoading width={150} height={150} /> :
            <div className='time-slots'>
              {availableSlots.length > 0 ? 
                availableSlots.map(
                  ({ dateTime: availableDateTime }: AvailableTimeSlot) => {
                    const availableDateTimeMoment = moment(availableDateTime)
                    const availableTime = availableDateTimeMoment.format("HH:mm")
                    return (
                      <div
                        onClick={() => chooseTimeHandler(availableDateTimeMoment)}
                        key={availableDateTime}
                        className={
                          `time-slot ${availableTime === dateTime.format("HH:mm")? "active" : ""}`
                      }>
                        {availableTime}
                      </div>
                    )}) : 
                <div className='no-time-slots'>לא נמצאו זמנים פנויים</div>
              }
            </div>
          }
        </>
      ) : 
      <div className='choose-date'>יש לבחור תאריך עתידי קודם</div>}
    </div>
  )
}

export default PickTime
