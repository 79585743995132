export const filteredByKeys = (data: Array<any>, keys: Array<string>, value: string) => {
  return data.filter(
    (d) => {
      let isFound = false
      keys.forEach((key) => {
        if (d[key].toLowerCase().includes(value.toLowerCase())) {
          isFound = true
        }
      })
      return isFound
    }
  )
}