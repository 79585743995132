import axios from "axios"

import { handleError } from "../utils/error"
import { patientsActions } from "../store/patients-store"
import { toast } from "react-toastify"
import { Moment } from "moment"

export const blockPermanentPatient = (patientId: string, isPermanentBlocked: boolean) => async (dispatch: any) => {
  try {
    await axios.patch(`patient/block-permanent/${patientId}`, {
      isPermanentBlocked
    })
    dispatch(patientsActions.updatePatientPermanentBlock({ patientId, isPermanentBlocked }))
    toast.success("מצב החסימה של הלקוח עודכן בהצלחה.")
  } catch (error) {
    handleError(error)
  }
}

export const multipleAppointmentsPatient = (patientId: string, canMultipleAppointments: boolean) => async (dispatch: any) => {
  try {
    await axios.patch(`patient/multiple-appointments/${patientId}`, {
      canMultipleAppointments
    })
    dispatch(patientsActions.updatePatientMultipleAppointments({ patientId, canMultipleAppointments }))
    toast.success("הרשאת הלקוח למספר פגישות עודכנה בהצלחה.")
  } catch (error) {
    handleError(error)
  }
}

export const blockTemporaryPatient = (patientId: string, dateTime: Moment | null) => async (dispatch: any) => {
  try {
    await axios.patch(`patient/block-temporary/${patientId}`, {
      dateTime
    })
    dispatch(patientsActions.updatePatientTemporaryBlock({ patientId, dateTime }))
  } catch (error) {
    handleError(error)
  }
}
