import { ChangeEventHandler, useState } from "react"
import Rodal from "rodal"

import { NewAppointment } from "../../types/appointments"

import "./BlockDateModal.scss"
import { useDispatch } from "react-redux"
import moment from "moment"
import { toast } from "react-toastify"
import { blockWeekday, blockedDate } from "../../actions/settings"

interface Props {
  date: Date
  onClose: () => void
}

const BlockDateModal = ({ date, onClose }: Props) => {
  const [values, setValues] = useState({
    from: null,
    to: null,
  })
  const dispatch = useDispatch<any>()

  const blockTemporary = async () => {
    const { from, to } = values
    if (!from || !to) {
      toast.error("יש לבחור תאריכים")
      return
    }
    const dateTimeFrom = moment(from)
    const dateTimeTo = moment(to)
    const durationInMinutes = dateTimeTo.diff(dateTimeFrom, 'minutes')
    await dispatch(blockedDate(dateTimeFrom, durationInMinutes))
    toast.success("יום נחסם בהצלחה")
    onClose()
  }
  const blockEntireDay = async () => {
    const dateTime = moment(date)
    await dispatch(blockedDate(dateTime, 1440))
    toast.success("יום נחסם בהצלחה")
    onClose()
  }

  const blockWeeklyDay = async () => {
    const day = moment(date).day() + ""
    await dispatch(blockWeekday(day))
    toast.success("יום נחסם בהצלחה")
    onClose()
  }

  const changeValuesHandler: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setValues((p) => ({ ...p, [target.name]: moment(target.value) }))
  }

  return (
    <Rodal className='block-date-modal' visible={true} onClose={onClose}>
      <div className='title'>חסימה מועדי פגישה</div>
      מתאריך:
      <input name='from' onChange={changeValuesHandler} type='datetime-local' />
      עד תאריך:
      <input name='to' onChange={changeValuesHandler} type='datetime-local' />
      <button onClick={blockTemporary} className='main-button'>
        חסימה זמנית
      </button>
      <button onClick={blockEntireDay} className='main-button'>
        חסימת כל היום
      </button>
      <button onClick={blockWeeklyDay} className='main-button'>
        חסימה שבועית של {moment(date).locale("he").format("ddd")}
      </button>
    </Rodal>
  )
}

export default BlockDateModal
