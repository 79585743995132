import { useState } from "react"

import TemporaryBlockModal from "../temporary-block-modal/TemporaryBlockModal"
import moment from "moment"

interface Props {
  patientId: string
  temporaryBlockedDate: string
}

const PatientTemporaryBlockButton = ({
  patientId,
  temporaryBlockedDate,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <div className='block-temporary' onClick={() => setIsModalOpen(true)}>
        {temporaryBlockedDate ? <>חסום: {moment(temporaryBlockedDate).format("DD/MM/YY HH:mm")}</> : (
          <>לחצו כאן כדי לחסום </>
        )}
      </div>
      {isModalOpen && (
        <TemporaryBlockModal
          patientId={patientId}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default PatientTemporaryBlockButton
