import moment from "moment"
import { Appointment } from "../../types/appointments"
import PatientInfoButton from "../patient-info-button/PatientInfoButton"
import { Patient } from "../../types/patients"
import DeleteAppointmentButton from "../delete-appointment-button/DeleteAppointmentButton"

interface Props {
  appointment: Appointment
  patient: Patient
}

const AppointmentTableRow = ({ appointment, patient }: Props) => {
  return (
    <tr className='patient-table-row'>
      <td>{moment(appointment.dateTime).format("DD/MM/YY HH:mm")}</td>
      <td>{appointment.durationInMinutes} דק'</td>
      <td className="patient-td">
        <PatientInfoButton patient={patient} />{patient.fullName}
      </td>
      <td><DeleteAppointmentButton appointmentId={appointment._id} /></td>
    </tr>
  )
}

export default AppointmentTableRow
