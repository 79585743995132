import { useState, Dispatch, SetStateAction } from "react"
import { useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"

import { Patient } from "../../types/patients"
import { NewAppointment } from "../../types/appointments"
import { filteredByKeys } from "../../utils/search"
import SmallLoading from "../small-loading/SmallLoading"

interface Props {
  title: string
  onChange: Dispatch<SetStateAction<NewAppointment>>
  patientId: string
  onSubmit: () => Promise<void>
}

const PickPatient = ({ onSubmit, onChange, patientId }: Props) => {
  const { patients } = useSelector((state: any) => state.patients)
  const [searchedValue, setSearchedValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const selectPatientHandler = (patientId: string) => {
    onChange((p) => ({ ...p, patientId }))
  }

  const submitHandler = async () => {
    setIsLoading(true)
    onSubmit().finally(() => setIsLoading(false))
  }

  const filteredPatients = filteredByKeys(
    patients,
    ["fullName", "phone"],
    searchedValue
  )

  return (
    <div className='pick-patient'>
      {/* <input
        className='search-input'
        onChange={({ target }) => setSearchedValue(target.value)}
        placeholder='חפש לפי טלפון או שם...'
      /> */}
      <div className='patients-list'>
        <div className='search-input1'>
          <CloseIcon onClick={() => setSearchedValue("")} />
          <input
            value={searchedValue}
            onChange={(e: any) => setSearchedValue(e?.target?.value)}
            type='text'
            placeholder='חיפוש...'
          />
        </div>
        {filteredPatients.map((patient: Patient) => (
          <div
            onClick={() => selectPatientHandler(patient._id)}
            key={patient._id}
            className={`patient ${patientId === patient._id ? "active" : ""}`}
          >
            <div className='name'>{patient.fullName}</div>
            <div className='phone'>{patient.phone}</div>
          </div>
        ))}
      </div>
      {isLoading ? (
        <SmallLoading height={40} />
      ) : (
        <button onClick={submitHandler} className='main-button'>
          קביעת תור
        </button>
      )}
    </div>
  )
}

export default PickPatient
