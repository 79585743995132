import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import App from "./App"
import store from "./store"

import "react-calendar/dist/Calendar.css"
import "react-toastify/dist/ReactToastify.css"
import 'rodal/lib/rodal.css'

import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
