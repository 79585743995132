import { useSelector } from "react-redux"
import moment from "moment"

import { Appointment } from "../../types/appointments"
import { Patient } from "../../types/patients"
import DeleteAppointmentButton from "../delete-appointment-button/DeleteAppointmentButton"

import "./AppointmentListItem.scss"
import PatientInfoModal from "../patient-info-modal/PatientInfoModal"
import { useState } from "react"
import PatientInfoButton from "../patient-info-button/PatientInfoButton"

interface Props {
  appointment: Appointment
}

const AppointmentListItem = ({ appointment }: Props) => {
  const { patients } = useSelector((state: any) => state.patients)

  const { dateTime, patient: patientId, _id } = appointment

  const patient = patients.find((patient: Patient) => patient._id === patientId)

  const appointmentTime = moment(dateTime).format("HH:mm")

  return (
    <div
      className={`appointment-list-item ${
        (appointment as any).isDeleted
          ? "red"
          : (appointment as any).isDone
          ? "blue"
          : ""
      }`}
    >
      <PatientInfoButton patient={patient} />
      <div className='texts2'>
        <div className='patient-name'>{patient.fullName}</div>
        <div className='line'>-</div>
        <div className='patient-name time'>{appointmentTime}</div>
      </div>
      {(appointment as any).isDeleted ? (
        <>מחוק</>
      ) : (appointment as any).isDone ? (
        <>בוצע</>
      ) : (
        <DeleteAppointmentButton appointmentId={_id} />
      )}
    </div>
  )
}

export default AppointmentListItem
