import { Checkbox, CheckboxProps } from "@material-ui/core"

import SmallLoading from "../small-loading/SmallLoading"

interface Props extends CheckboxProps {
  isLoading: boolean
}

const LoadingCheckbox = ({ isLoading, ...restProps }: Props) => {
  return (
    <>
      {isLoading ? <SmallLoading height={17} /> : <Checkbox {...restProps} />}
    </>
  )
}

export default LoadingCheckbox
