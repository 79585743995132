import { useSelector } from "react-redux"
import "./ListAppointments.scss"
import Table from "../../components/table/Table"
import AppointmentTableRow from "../../components/appointment-table-row/AppointmentTableRow"
import { Appointment } from "../../types/appointments"
import { Patient } from "../../types/patients"
import moment from "moment"

const ListAppointments = () => {
  const { appointments } = useSelector((state: any) => state.appointments)
  const sortedAppointments = [...appointments].sort((a, b) =>
    moment(a.dateTime).isBefore(moment(b.dateTime)) ? -1 : 1
  )
  const { patients } = useSelector((state: any) => state.patients)

  return (
    <div className='list-appointments'>
      <Table headerCells={["תאריך", "משך", "לקוח", ""]}>
        {sortedAppointments.map((appointment: Appointment) => {
          const patient = patients.find(
            (patient: Patient) => patient._id === appointment.patient
          )
          return (
            <AppointmentTableRow
              key={appointment._id}
              patient={patient}
              appointment={appointment}
            />
          )
        })}
      </Table>
    </div>
  )
}

export default ListAppointments
