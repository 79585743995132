import { PropsWithChildren } from "react"

import useMobile from "../../hooks/useMobile"

import "./Table.scss"

interface Props extends PropsWithChildren {
  headerCells: string[]
  mobileHeaderCells?: string[]
}

const Table = ({ headerCells, mobileHeaderCells, children }: Props) => {
  const isMobile = useMobile()
  
  return (
    <div className='table'>
      <table>
        <thead>
          <tr>
            {isMobile && mobileHeaderCells
              ? mobileHeaderCells.map((cell: string) => (
                  <th key={cell}>{cell}</th>
                ))
              : headerCells.map((cell: string) => <th key={cell}>{cell}</th>)}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  )
}

export default Table
