import { toast } from "react-toastify"
import axios from "axios"

import { handleError } from "../utils/error"
import { settingsActions } from "../store/settings-store"
import { Moment } from "moment"

export const setNewPopup = (text: string) => async (dispatch: any) => {
  try {
    const { data: popup } = await axios.post("setting/popup", { text })
    console.log(popup)
    await dispatch(settingsActions.setPopup(popup))
    toast.success("מודעה פורסמה בהצלחה.")
  } catch (error) {
    handleError(error)
  }
}

export const deleteCurrentPopup = (id: string) => async (dispatch: any) => {
  try {
    await axios.delete(`setting/popup/${id}`)
    await dispatch(settingsActions.setPopup(null))
    toast.success("מודעה נמחקה בהצלחה.")
  } catch (error) {
    handleError(error)
  }
}

export const getCurrentPopup = () => async (dispatch: any) => {
  try {
    const { data } = await axios.post("setting/popup")
    toast.success("מודעה הוגדרה בהצלחה.")
  } catch (error) {
    handleError(error)
  }
}

export const deleteBlockedDate = (blockedDateId: string) => async (dispatch: any) => {
  try {
    await axios.delete(`setting/blocked-date/${blockedDateId}`)
    dispatch(settingsActions.deleteBlockedDate(blockedDateId))
    toast.success("החסימה נמחקה בהצלחה")
  } catch (error) {
    handleError(error)
  }
}

export const blockedDate = (dateTime: Moment, durationInMinutes: number) => async (dispatch: any) => {
  try {
    const { data } = await axios.post("setting/block-datetime", { dateTime, durationInMinutes })
    dispatch(settingsActions.addBlockedDate(data))
  } catch (error) {
    handleError(error)
  }
}
export const blockWeekday = (day: string) => async (dispatch: any) => {
  try {
    const { data } = await axios.post("setting/block-weekday", { day })
    dispatch(settingsActions.addBlockedDate(data))
  } catch (error) {
    handleError(error)
  }
}
