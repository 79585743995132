import moment from "moment"
import AddIcon from "@mui/icons-material/Add"
import "./Settings.scss"
import { useSelector } from "react-redux"
import { useState } from "react"
import NewAppointmentModal from "../../components/new-appointment-modal/NewAppointmentModal"
import Calendar from "../../components/calendar/Calendar"
import AppointmentListItem from "../../components/appointment-list-item/AppointmentListItem"
import { Appointment } from "../../types/appointments"
import { BlockedDate } from "../../types/settings"
import BlockedDateChip from "../../components/blocked-date-chip/BlockedDateChip"
import BlockDateModal from "../../components/block-date-modal/BlockDateModal"

const Settings = () => {
  const { blockedDates } = useSelector((state: any) => state.settings)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const selectedBlockedDates = blockedDates.filter(
    ({ dateTime, isAutomaticWeekly }: BlockedDate) => (moment(selectedDate).isSame(dateTime, "day") || (isAutomaticWeekly && moment(selectedDate).isoWeekday() === moment(dateTime).isoWeekday()))
  )
  return (
    <div className='settings'>
      <div className='title'>חסימת ימים</div>
      <Calendar
        tileClassName={({ date }) => {
          const entireDayBlocked =
            (blockedDates.filter(
              ({
                isAutomaticWeekly,
                durationInMinutes,
                dateTime,
              }: BlockedDate) =>
                (durationInMinutes === 1440 || isAutomaticWeekly) &&
                (moment(dateTime).isSame(moment(date), "day") || (isAutomaticWeekly && moment(date).isoWeekday() === moment(dateTime).isoWeekday()
                ))
            ).length > 0)
          if (entireDayBlocked) {
            return "red-tile"
          }
          const hasBlockedDates =
            blockedDates.filter(({ dateTime }: BlockedDate) =>
              moment(dateTime).isSame(moment(date), "day")
            ).length > 0
          if (hasBlockedDates) {
            return "orange-tile"
          }
          return ""
        }}
        value={selectedDate}
        className='calendar'
        onChange={(date: Date) => setSelectedDate(date)}
      />
      <button
        className='main-button add-blocked-date-button'
        onClick={() => setIsModalOpened(true)}
        disabled={!selectedDate}
      >
        חסימת תאריך
      </button>
      <div className='blocked-dates-chips'>
        {selectedDate ? (
          selectedBlockedDates.length > 0 ? (
            selectedBlockedDates.map((blockedDate: BlockedDate) => (
              <BlockedDateChip key={blockedDate._id} {...blockedDate} />
            ))
          ) : (
            <span>אין חסימות ביום זה</span>
          )
        ) : (
          <span>יש לבחור תאריך</span>
        )}
      </div>
      {isModalOpened && (
        <BlockDateModal date={selectedDate} onClose={() => setIsModalOpened(false)} />
      )}
    </div>
  )
}

export default Settings
