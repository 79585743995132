import { useState } from "react"
import PersonIcon from "@mui/icons-material/Person"

import PatientInfoModal from "../patient-info-modal/PatientInfoModal"
import { Patient } from "../../types/patients"

import "./PatientInfoButton.scss"

interface Props {
  patient: Patient
}

const PatientInfoButton = ({ patient }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <PersonIcon
        onClick={() => setIsModalOpen(true)}
        className='person-icon'
      />
      {isModalOpen && (
        <PatientInfoModal
          patient={patient}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default PatientInfoButton
