import { Dispatch, SetStateAction } from "react"
import moment, { Moment } from "moment"

import Calendar from "../calendar/Calendar"
import { NewAppointment } from "../../types/appointments"

interface Props {
  title: string
  onChange: Dispatch<SetStateAction<NewAppointment>>
  dateTime: Moment | null
}

const PickDate = ({ onChange, dateTime }: Props) => {
  const changeHandler = (date: Date) => {
    onChange((p: NewAppointment) => ({ ...p, dateTime: moment(date) }))
  }
  
  return (
    <div className='pick-date'>
      <Calendar defaultValue={dateTime ? new Date(dateTime.toString()) : undefined} onChange={changeHandler} className='calendar' />
    </div>
  )
}

export default PickDate
