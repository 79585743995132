import { createSlice } from "@reduxjs/toolkit"
import { Settings } from "../../types/settings"

const initialState: Settings = {
  popup: {
    text: null,
    id: null,
  },
  blockedDates: []
}

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setPopup(state, action) {
      if (action.payload) {
        state.popup = action.payload
      } else {
        state.popup = {
          text: null,
          id: null,
        }
      }
    },
    setBlockedDates(state, action) {
      state.blockedDates = action.payload
    },
    deleteBlockedDate(state, action) {
      state.blockedDates = state.blockedDates.filter((blockedDate: any) => blockedDate._id !== action.payload)
    },
    addBlockedDate(state, action) {
      state.blockedDates.push(action.payload)
    }
  },
})

export const settingsActions = settingsSlice.actions
export const settingsReducer = settingsSlice.reducer
