import axios from "axios"

import { handleError } from "../utils/error"
import { authActions } from "../store/auth-store"
import { patientsActions } from "../store/patients-store"
import { appointmentsActions } from "../store/appointments-store"
import { settingsActions } from "../store/settings-store"

export const authUserByCredentials = (username: string, password: string) => async (dispatch: any) => {
  try {
    const { data: { token } } = await axios.post("admin/login", {
      username,
      password
    })
    await dispatch(authActions.login(token))
    dispatch(getUserData())
  } catch (error) {
    handleError(error)
  }
}

export const getUserData = () => async (dispatch: any) => {
  try {
    const { data: patients } = await axios.get("patient/all")
    const { data: appointments } = await axios.get("appointment/all")
    const { data: popup } = await axios.get("setting/popup")
    const { data: blockedDates } = await axios.get("setting/blocked-date")
    dispatch(patientsActions.setPatients(patients))
    dispatch(appointmentsActions.setAppointments(appointments))
    dispatch(settingsActions.setPopup(popup))
    dispatch(settingsActions.setBlockedDates(blockedDates))
  } catch (error: any) {
    if (error.response.status === 403) {
      dispatch(authActions.logout())
    } else {
      handleError(error)
    }
  }
}
