import "./BigLoading.scss"

const BigLoading = () => {
  return (
    <div className='big-loading'>
      <img
        src='/big-loading.svg'
        alt='Animated loading with pacman style.'
      />
      אוסף מידע...
    </div>
  )
}

export default BigLoading
