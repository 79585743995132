import { useSelector } from "react-redux"

import Table from "../../components/table/Table"
import { WaitingPatient } from "../../types/patients"
import { Patient } from "../../types/patients"
import WaitingTableRow from "../../components/waiting-table-row/WaitingTableRow"


import "./Waitings.scss"

const Waitings = () => {
  const { waitings, patients } = useSelector((state: any) => state.patients)

  return (
    <div className='waitings'>
      <Table headerCells={["תאריך", "לקוח", ""]}>
        {waitings.map((waiting: WaitingPatient) => {
          const patient = patients.find(
            (patient: Patient) => patient._id === waiting.patient
          )
          return (
            <WaitingTableRow
              key={waiting._id}
              patient={patient}
              waiting={waiting}
            />
          )
        })}
      </Table>
    </div>
  )
}

export default Waitings
