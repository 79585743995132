import { MenuItem } from "@material-ui/core"

import { Patient } from "../../types/patients"
import MenuListComposition from "../menu-list-composition/MenuListComposition"
import PatientMultipleAppointmentsCheckbox from "../patient-multiple-appointments-checkbox/PatientMultipleAppointmentsCheckbox"
import PatientPermanentBlockCheckbox from "../patient-permanent-block-checkbox/PatientPermanentBlockCheckbox"
import PatientTemporaryBlockButton from "../patient-temporary-block-button/PatientTemporaryBlockButton"
import { useState } from "react"

const MobileTableRow = (patient: Patient) => {
  return (
    <tr className='patient-table-row'>
      <td>{patient.fullName}</td>
      <td>{patient.phone}</td>
      <td className="menu-button">
        <MenuListComposition>
          <MenuItem className='menu-item'>
            יכול יותר מתור אחד
            <PatientMultipleAppointmentsCheckbox
              patientId={patient._id}
              checked={patient.canMultipleAppointments}
            />
          </MenuItem>
          <MenuItem className='menu-item'>
            חסום לצמיתות
            <PatientPermanentBlockCheckbox
              patientId={patient._id}
              checked={patient.isPermanentBlocked}
            />
          </MenuItem>
          <MenuItem className='menu-item'>
            <PatientTemporaryBlockButton
              patientId={patient._id}
              temporaryBlockedDate={patient.temporaryBlockedDate}
            />
          </MenuItem>
        </MenuListComposition>
      </td>
    </tr>
  )
}

export default MobileTableRow
