import { configureStore } from "@reduxjs/toolkit"

import { authReducer } from "./auth-store"
import { settingsReducer } from "./settings-store"
import { Settings } from "../types/settings"
import { Auth } from "../types/auth"
import { patientsReducer } from "./patients-store"
import { appointmentsReducer } from "./appointments-store"

const store = configureStore({
  reducer: {
    auth: authReducer,
    patients: patientsReducer,
    appointments: appointmentsReducer,
    settings: settingsReducer,
  },
})

export default store

export interface RootState {
  auth: Auth
  settings: Settings
}
