import { Patient, WaitingPatient } from "../../types/patients"

interface Props {
  waiting: WaitingPatient
  patient: Patient
}

const WaitingTableRow = ({ waiting, patient }: Props) => {
  return <div>WaitingTableRow</div>
}

export default WaitingTableRow
