import Rodal from "rodal"

import "./ConfirmModal.scss"

interface Props {
  title: string
  description: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmModal = ({ title, description, onClose, onConfirm }: Props) => {
  const confirmHandler = () => {
    onClose()
    onConfirm()
  }

  return (
    <Rodal className='confirm-modal' visible={true} onClose={onClose}>
      <div className='title'>{title}</div>
      <p>{description}</p>
      <div className='buttons'>
        <button className='main-button confirm' onClick={confirmHandler}>
          כן
        </button>
        <button className='main-button cancel' onClick={onClose}>
          לא
        </button>
      </div>
    </Rodal>
  )
}

export default ConfirmModal
