import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  token: localStorage.getItem("access-token"),
  fullName: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login(state, action) {
      const token = action.payload
      state.token = token
      localStorage.setItem("access-token", token)
    },
    logout(state) {
      localStorage.removeItem("access-token")
      state.token = null
      state.fullName = null
    },
  },
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
