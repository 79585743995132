import { useState } from "react"
import { useSelector } from "react-redux"

import SearchInput from "../../components/search-input/SearchInput"
import Table from "../../components/table/Table"
import { filteredByKeys } from "../../utils/search"
import PatientTableRow from "../../components/patient-table-row/PatientTableRow"
import { Patient } from "../../types/patients"

import "./Patients.scss"

const Patients = () => {
  const { patients } = useSelector((state: any) => state.patients)
  const [searchedValue, setSearchedValue] = useState("")

  const filteredPatients = filteredByKeys(
    patients,
    ["fullName", "phone"],
    searchedValue
  )

  return (
    <div className='patients'>
      <SearchInput value={searchedValue} setValue={setSearchedValue} />
      <Table
        headerCells={[
          "שם",
          "נייד",
          "יכול יותר מתור אחד",
          "חסום תמיד",
          "חסום זמנית",
        ]}
        mobileHeaderCells={["נייד", "שם", " "]}
      >
        {filteredPatients.map((patient: Patient) => (
          <PatientTableRow key={patient._id} {...patient} />
        ))}
      </Table>
    </div>
  )
}

export default Patients
