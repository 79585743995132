import { Chip } from "@mui/material"
import moment from "moment"

import { BlockedDate } from "../../types/settings"

import "./BlockedDateChip.scss"
import { useState } from "react"
import SmallLoading from "../small-loading/SmallLoading"
import { useDispatch } from "react-redux"
import { deleteBlockedDate } from "../../actions/settings"

const BlockedDateChip = ({
  _id,
  dateTime,
  isAutomaticWeekly,
  durationInMinutes,
}: BlockedDate) => {
  const [isLoading, setIsLoading] = useState(false)
  const momentDateTime = moment(dateTime)
  const dispatch = useDispatch<any>()
  let value

  if (isAutomaticWeekly) {
    value = "יום חסום שבועי"
  } else if (durationInMinutes === 1440) {
    value = "24 שעות"
  } else {
    value = `${momentDateTime.format("HH:mm")} - ${momentDateTime
      .add(durationInMinutes, "minutes")
      .format("HH:mm")}`
  }

  const deleteBlockedDateHandler = async () => {
    setIsLoading(true)
    await dispatch(deleteBlockedDate(_id))
    setIsLoading(false)
  }

  return (
    <>
      <Chip
        className={"blocked-date-chip"}
        label={value}
        deleteIcon={isLoading ? <SmallLoading height={24} /> : undefined}
        onDelete={deleteBlockedDateHandler}
      />
    </>
  )
}

export default BlockedDateChip
