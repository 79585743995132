import useMobile from "../../hooks/useMobile"
import { Patient } from "../../types/patients"

import MobileTableRow from "./MobileTableRow"
import DesktopTableRow from "./DesktopTableRow"

import "./PatientTableRow.scss"

const PatientTableRow = (patient: Patient) => {
  const isMobile = useMobile()

  return isMobile ? (
    <MobileTableRow {...patient} />
  ) : (
    <DesktopTableRow {...patient} />
  )
}

export default PatientTableRow
