import { FormEventHandler, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import Loading from "../../components/small-loading/SmallLoading"
import { authUserByCredentials } from "../../actions/auth"

import "./Login.scss"

const Login = () => {
  const dispatch = useDispatch<any>()
  const [isLoading, setIsLoading] = useState(false)

  const submitHandler: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const username = (form[0] as HTMLInputElement).value
    const password = (form[1] as HTMLInputElement).value

    if (!username || !password) {
      toast.error("אנא מלא/י את כל השדות.")
      return
    }

    setIsLoading(true)
    await dispatch(authUserByCredentials(username, password))
    setIsLoading(false)
  }

  return (
    <div className="login">
      <form onSubmit={submitHandler}>
        <div className="title">התחברות</div>
        <p>או התחבר/י דרך משתמש</p>
        <input name="username" placeholder="שם משתמש" />
        <input type="password" name="password" placeholder="סיסמה" />
        <div className="submit">
          {isLoading ?
            <Loading height={39} /> : 
            <button className="main-button" type="submit">התחבר</button>
          }
        </div>
      </form>
    </div>
  )
}

export default Login
