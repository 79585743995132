import { useState } from "react"
import { useDispatch } from "react-redux"
import DeleteIcon from "@mui/icons-material/Delete"
import { toast } from "react-toastify"

import { deleteAppointmentById } from "../../actions/appointments"
import SmallLoading from "../small-loading/SmallLoading"
import ConfirmModal from "../confirm-modal/ConfirmModal"

import "./DeleteAppointmentButton.scss"

interface Props {
  appointmentId: string
}

const DeleteAppointmentButton = ({ appointmentId }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch<any>()

  const deleteAppointmentHandler = async () => {
    setIsLoading(true)
    await dispatch(deleteAppointmentById(appointmentId))
    setIsLoading(false)
    toast.success("הפגישה נמחקה בהצלחה.")
  }

  return (
    <>
      {isLoading ? (
        <SmallLoading width={22} height={22} />
      ) : (
        <DeleteIcon
          onClick={() => setIsModalOpen(true)}
          className='delete-appointment-button'
        />
      )}
      {isModalOpen && (
        <ConfirmModal
          title='אזהרה'
          description='האם את/ה בטוח/ה שברצונך למחוק את הפגישה?'
          onConfirm={deleteAppointmentHandler}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default DeleteAppointmentButton
