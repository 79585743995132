import Rodal from "rodal"
import PersonIcon from "@mui/icons-material/Person"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import { useSelector } from "react-redux"
import moment from "moment"
import "moment/locale/he"

import { Patient } from "../../types/patients"

import { Appointment } from "../../types/appointments"
import DeleteAppointmentButton from "../delete-appointment-button/DeleteAppointmentButton"

import "./PatientInfoModal.scss"

interface Props {
  patient: Patient
  onClose: () => void
}

const PatientInfoModal = ({ patient, onClose }: Props) => {
  const { appointments } = useSelector((state: any) => state.appointments)
  const patientAppointments = appointments.filter(
    (appointment: Appointment) => appointment.patient === patient._id
  )
  const callPatientHandler = () => {
    window.location.href = "tel:" + patient.phone
  }
  return (
    <Rodal className='patient-info-modal' visible={true} onClose={onClose}>
      <div className='header'>
        {patient.fullName}
        <PersonIcon className='person-icon' />
      </div>
      <div className='info'>
        <div className='info-option'>
          <div className='value' onClick={callPatientHandler}>
            {patient.phone}
          </div>
          <LocalPhoneIcon onClick={callPatientHandler} />
        </div>
      </div>
      <div className='title'>
        <span>פגישות:</span>
      </div>
      <div className='appointments'>
        {patientAppointments.map(
          ({ dateTime, _id: appointmentId }: Appointment) => {
            const appointmentTime = moment(dateTime)
              .locale("he")
              .format("DD/MM/YY HH:mm יום ddd")
            return (
              <div className='appointment-item'>
                <div className='patient-name'>{appointmentTime}</div>
                <DeleteAppointmentButton appointmentId={appointmentId} />
              </div>
            )
          }
        )}
      </div>
    </Rodal>
  )
}

export default PatientInfoModal
