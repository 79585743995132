import { Sidebar as RPSidebar, Menu, MenuItem, useProSidebar, } from "react-pro-sidebar"
import { useLocation, useNavigate } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import LogoutIcon from "@mui/icons-material/Logout"
import { useDispatch, useSelector } from "react-redux"

import { getAllRoutes } from "../../utils/routes"
import { authActions } from "../../store/auth-store"

import "./Sidebar.scss"
import { useEffect } from "react"

const Sidebar = () => {
  const { collapsed, collapseSidebar } = useProSidebar()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => collapseSidebar(true), [pathname])

  const logoutHandler = () => {
    collapseSidebar(true)
    dispatch(authActions.logout())
  }

  return (
    <>
      <RPSidebar rootStyles={{
          position: "fixed", 
          top: 0, 
          right: 0, 
          minHeight: "100vh",
        }} 
        className={`${collapsed ? "" : "open"} sidebar`}>
        <Menu>
          <img src="/logo.png" alt="לוגו של המספרה של אוראל" />
          {getAllRoutes().map(({ iconComponent: Icon, label, path }) => (
            <MenuItem key={path} onClick={() => navigate(path)} className={`sidebar-item ${pathname === path ? "active" : ""}`}>
              <Icon />
              {label}
            </MenuItem>
          ))}
          <MenuItem onClick={logoutHandler} className="sidebar-item">
            <LogoutIcon />
            התנתקות
          </MenuItem>
        </Menu>
      </RPSidebar>
      <button className="sidebar-toggle-button" onClick={() => collapseSidebar()}>
        {collapsed ? <MenuIcon /> : <CloseIcon />}
      </button>
    </>
  )
}

export default Sidebar
