import { Link } from "react-router-dom"

import "./NotFound.scss"

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="title">אופס...</div>
      המקום המבוקש אינו קיים,
      <Link to="/">לחצו כאן למעבר לדף הבית.</Link>
      <img
        src="not-found.svg"
        alt="Nature picture with 404 logo inside of it."
      />
    </div>
  )
}

export default NotFound
