import { useState, FormEventHandler } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import { setNewPopup, deleteCurrentPopup } from "../../actions/settings"
import Loading from "../../components/small-loading/SmallLoading"

import "./Popup.scss"

const Popup = () => {
  const { text, _id } = useSelector((state: any) => state.settings.popup)
  const [popupText, setPopupText] = useState(text || "")
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch<any>()

  const submitHandler: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    if (!popupText || popupText.length < 6) {
      toast.error("תוכן ההודעה חייב להיות מינימום 6 תווים.")
      return
    }

    if(popupText === (text || "")) {
      toast.error("יש לבצע שינויים במודעה.")
      return
    }

    setIsLoading(true)
    await dispatch(setNewPopup(popupText))
    setIsLoading(false)
  }

  const deletePopupHandler = async () => {
    if (!_id) {
      toast.error("אין מודעה כרגע.")
      return
    }
    setIsLoading(true)
    await dispatch(deleteCurrentPopup(_id))
    setPopupText("")
    setIsLoading(false)
  }

  return (
    <div className='popup'>
      <form onSubmit={submitHandler}>
        <h1>מודעה</h1>
        <textarea onChange={(e) => setPopupText(e.target.value)} value={popupText} />
        <div className='buttons'>
          {isLoading ? (
            <Loading height={38} />
          ) : (
            <>
              <button
                type='button'
                onClick={deletePopupHandler}
                className='main-button delete'
              >
                מחיקה
              </button>
              <button type='submit' className='main-button publish'>
                פרסום
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default Popup
