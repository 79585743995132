import { useState, ChangeEventHandler } from "react"
import Rodal from "rodal"

import { NewAppointment } from "../../types/appointments"

import "./TemporaryBlockModal.scss"
import { useDispatch } from "react-redux"
import moment, { Moment } from "moment"
import { toast } from "react-toastify"
import { createNewAppointment } from "../../actions/appointments"
import { blockTemporaryPatient } from "../../actions/patients"
import SmallLoading from "../small-loading/SmallLoading"

interface Props {
  patientId: string
  onClose: () => void
}

const TemporaryBlockModal = ({ patientId, onClose }: Props) => {
  const [dateTimeValue, setDateTimeValue] = useState<Moment | null>(null)
  const [loadingOptions, setLoadingOptions] = useState({
    block: false,
    unblock: false,
  })
  const dispatch = useDispatch<any>()

  const blockHandler = async () => {
    if (!dateTimeValue) {
      toast.error("יש לבחור מועד זמן.")
      return
    }
    setLoadingOptions((p) => ({ ...p, block: true }))
    await dispatch(blockTemporaryPatient(patientId, dateTimeValue))
    setLoadingOptions((p) => ({ ...p, block: false }))
    toast.success("החסימה נקבעה בהצלחה.")
    onClose()
  }

  const unblockHandler = async () => {
    setLoadingOptions((p) => ({ ...p, unblock: true }))
    await dispatch(blockTemporaryPatient(patientId, null))
    setLoadingOptions((p) => ({ ...p, unblock: false }))
    toast.success("החסימה בוטלה בהצלחה.")
    onClose()
  }

  const changeHandler: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setDateTimeValue(moment(target.value))
  }

  return (
    <Rodal className='temporary-block-modal' visible={true} onClose={onClose}>
      חסום עד לתאריך ושעה:
      <input
        name='to'
        onChange={changeHandler}
        className='date-time-to'
        type='datetime-local'
      />
      <div className='buttons'>
        {loadingOptions.unblock ? (
          <SmallLoading height={26}width={83.13} />
        ) : (
          <button onClick={unblockHandler} className='main-button delete'>
            בטל חסימה
          </button>
        )}
        {loadingOptions.block ? (
          <SmallLoading height={26} width={45.26} />
        ) : (
          <button onClick={blockHandler} className='main-button save'>
            שמור
          </button>
        )}
      </div>
    </Rodal>
  )
}

export default TemporaryBlockModal
