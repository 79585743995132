import axios from "axios"

import store from "../store"

export const configureAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  
  axios.interceptors.request.use(async config => {
    const { auth } = store.getState()
    
    if (auth.token) {
      config.headers.Authorization = `Bearer ${auth.token}`
    }
    return config
  })
}
