import { useState } from "react"
import { useSelector } from "react-redux"

import Calendar from "../../components/calendar/Calendar"
import AppointmentListItem from "../../components/appointment-list-item/AppointmentListItem"
import { Appointment } from "../../types/appointments"
import NewAppointmentModal from "../../components/new-appointment-modal/NewAppointmentModal"

import "./CalendarAppointments.scss"
import moment from "moment"

const CalendarAppointments = () => {
  let { appointments } = useSelector((state: any) => state.appointments)
  // sorted appointments by date where isDone appointments and isDeleted appointments are last
  const sortedAppointments = [...appointments]
    .sort((a, b) => (moment(a.dateTime).isBefore(moment(b.dateTime)) ? -1 : 1))
    .sort((a, b) => {
      if ((a as any).isDone && !(b as any).isDone) {
        return 1
      }
      if (!(a as any).isDone && (b as any).isDone) {
        return -1
      }
      if ((a as any).isDeleted && !(b as any).isDeleted) {
        return 1
      }
      if (!(a as any).isDeleted && (b as any).isDeleted) {
        return -1
      }
      return 0
    })

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const selectedAppointments = sortedAppointments.filter(
    (appointment: Appointment) => {
      if (selectedDate) {
        return moment(selectedDate).isSame(moment(appointment.dateTime), "day")
      }
    }
  )
  return (
    <div className='calendar-appointments'>
      <button className='main-button' onClick={() => setIsModalOpened(true)}>
        הוספה
      </button>
      {isModalOpened && (
        <NewAppointmentModal onClose={() => setIsModalOpened(false)} />
      )}
      <Calendar
        value={selectedDate}
        tileClassName={({ date }) => {
          const hasAppointments = sortedAppointments.filter(
            (appointment: Appointment) => {
              return moment(date).isSame(moment(appointment.dateTime), "day")
            }
          )
          const deletedAppointments = hasAppointments.filter(
            (appointment: Appointment) => {
              return (appointment as any).isDeleted
            }
          )
          const doneAppointments = hasAppointments.filter(
            (appointment: Appointment) => {
              return (appointment as any).isDone
            }
          )

          if (hasAppointments.length > 0) {
            if (hasAppointments.length === deletedAppointments.length) {
              return "red-bg"
            } else if (
              hasAppointments.length === doneAppointments.length ||
              hasAppointments.length ===
                doneAppointments.length + deletedAppointments.length
            ) {
              return "blue-bg"
            }
            return "green-bg"
          }
          return ""
        }}
        className='calendar'
        onChange={(date: Date) => setSelectedDate(date)}
      />
      <div className='appointments-list'>
        {selectedDate ? (
          selectedAppointments.map((appointment: Appointment) => (
            <AppointmentListItem
              key={appointment._id}
              appointment={appointment}
            />
          ))
        ) : (
          <span>יש לבחור תאריך</span>
        )}
      </div>
    </div>
  )
}

export default CalendarAppointments
