import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getAllRoutes } from '../../utils/routes'
import NotFound from '../../pages/not-found/NotFound'
import Login from '../../pages/loginn/Login'

const Router = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.token)

  if (!isLoggedIn) return <Login />

  return (
    <Routes>
      {getAllRoutes().map(({ path, pageComponent: Page }) => (
        <Route
          key={path}
          path={path}
          element={<Page />}
        />
        ))}
        <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Router
