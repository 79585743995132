import { createSlice } from "@reduxjs/toolkit"
import { Appointment } from "../../types/appointments"

const initialState = {
  appointments: [],
  availableSlots: [],
}

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: initialState,
  reducers: {
    setAppointments(state, action) {
      console.log(action.payload);
      state.appointments = action.payload
    },
    setAvailableSlots(state, action) {
      state.availableSlots = action.payload
    },
    addAppointment(state, action) {
      state.appointments.push(action.payload as never)
    },
    deleteAppointment(state, action) {
      state.appointments = state.appointments.filter(
        (appointment: Appointment) => appointment._id !== action.payload
      )
      // if(state?.appointments?.find((appointment: Appointment) => appointment?._id === action?.payload)) {
      //   (state.appointments.find((appointment: Appointment) => appointment._id === action.payload) as  any).isDeleted = true
      // }
    }
  },
})

export const appointmentsActions = appointmentsSlice.actions
export const appointmentsReducer = appointmentsSlice.reducer
