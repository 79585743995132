import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ProSidebarProvider } from "react-pro-sidebar"
import { ToastContainer } from "react-toastify"
import moment from "moment-timezone"

import Router from "./components/router/Router"
import Sidebar from "./components/sidebar/Sidebar"
import { configureAxios } from "./utils/axios"
import { getUserData } from "./actions/auth"
import { RootState } from "./store"
import BigLoading from "./components/big-loading/BigLoading"

const App = () => {
  const isLoggedIn = !!useSelector((state: RootState) => state.auth.token)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch<any>()
  configureAxios()

  moment.tz.setDefault("Israel")

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true)
      dispatch(getUserData()).finally(() => {
        setIsLoading(false)
      })
    }
  }, [isLoggedIn, dispatch, setIsLoading])

  return (
      <BrowserRouter>
        <ProSidebarProvider>
          {isLoading ? <BigLoading /> : <Router />}
          {isLoggedIn && <Sidebar />}
          <ToastContainer />
        </ProSidebarProvider>
      </BrowserRouter>
  )
}

export default App
// #TODO: Handle toasts success and errors
// Choose interface or type be conssistent
// css names and coloring names
// alias all _id or id to appointmentId etc...
// css duplications in appointmentListItem
// ADD DELETE BUTTON TO POPUP
// remove all never and any types
// maybe change piopup to notifications or smth like this because of firebase in furutre
// when create new appointment calendar tiles are not square.
// close icon on modal is broken, and check the all css with classes i dont want native element anymore.
// check all importants in css.
// center patients in appointments table.
// close icon on all modal is broken
//bug in apointments in calendar
// loading button
// #TODO: Handle toasts success and errors
// Choose interface or type be conssistent
// css names and coloring names
// alias all _id or id to appointmentId etc...
// css duplications in appointmentListItem
// ADD DELETE BUTTON TO POPUP
// remove all never and any types
// maybe change piopup to notifications or smth like this because of firebase in furutre
// when create new appointment calendar tiles are not square.
// close icon on modal is broken, and check the all css with classes i dont want native element anymore.
// check all importants in css.
// center patients in appointments table.
// close icon on all modal is broken
//bug in apointments in calendar