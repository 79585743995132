import { createSlice } from "@reduxjs/toolkit"
import { PatientState } from "../../types/patients"

const initialState: PatientState = {
  patients: [],
  waitings: [],
}

const patientsSlice = createSlice({
  name: "patients",
  initialState: initialState,
  reducers: {
    setPatients(state, action) {
      state.patients = action.payload
    },
    updatePatientPermanentBlock(state, action) {
      const { patientId, isPermanentBlocked } = action.payload
      const patientIndex = state.patients.findIndex((patient: any) => patient._id === patientId)
      state.patients[patientIndex].isPermanentBlocked = isPermanentBlocked
    },
    updatePatientMultipleAppointments(state, action) {
      const { patientId, canMultipleAppointments } = action.payload
      const patientIndex = state.patients.findIndex((patient: any) => patient._id === patientId)
      state.patients[patientIndex].canMultipleAppointments = canMultipleAppointments
    },
    updatePatientTemporaryBlock(state, action) {
      const { patientId, dateTime } = action.payload
      const patientIndex = state.patients.findIndex((patient: any) => patient._id === patientId)
      state.patients[patientIndex].temporaryBlockedDate = dateTime ? dateTime.toString() : dateTime
    }
  },
})

export const patientsActions = patientsSlice.actions
export const patientsReducer = patientsSlice.reducer
