interface Props {
    width?: number | string
    height?: number | string
}

const SmallLoading = ({ width, height }: Props) => {
    return (
        <img
            width={width}
            height={height}
            src="/small-loading.svg"
            alt="Gear wheel spinning animation as loading."
        />
    )
}

export default SmallLoading